import React from "react";
import "./accountPage.scss";




export default function AccountPage() {
  let arrBody =[
    {account: "Leverage", basic: "200:1", gold: "300:1", platinum: "400:1", vip: "Up to 30% Discount", diamond: "Up to 30% Discount"},
    {account: "Personal account manager", basic: "No", gold: "YES", platinum: "YES", vip: "Daily Review", diamond: "Daily Review"},
    {account: "Swap discount", basic: "No", gold: "25%", platinum: "50%", vip: "No widthrawal fee", diamond: "No widthrawal fee"},
    {account: "Fifth decimal", basic: "YES", gold: "YES", platinum: "YES", vip: "Via email or with the Account Manager", diamond: "Via email or with the Account Manager"},
    {account: "Free VPS", basic: "No", gold: "No", platinum: "YES", vip: "Full Access to All Assets", diamond: "Full Access to All Assets"},
    {account: "Customized investment news", basic: "No", gold: "YES", platinum: "YES", vip: "Up to 25% SWAP Discount", diamond: "No SWAP"},
  ]

  let arrTypes = [
    {title: "Silver", leverage: "200:1", accountManager: "No", swap: "No", decimal: "YES", VPS: "No", news: "No"},
    {title: "Gold", leverage: "300:1", accountManager: "YES", swap: "25%", decimal: "YES", VPS: "No", news: "YES"},
    {title: "Platinum", leverage: "400:1", accountManager: "YES", swap: "50%", decimal: "YES", VPS: "YES", news: "YES"},
    // {title: "", leverage: "", accountManager: "", swap: "", decimal: "", VPS: "", news: ""},

  ]
 return(
  <div className="accountPage container">
    <div className="indent__title_auxilary">
      <h1 className='indent__title_auxilary'>Real Account</h1>
      <p className="indent__title_auxilary">Opening an account with Intervide is safe and easy with our online account verification. Join us today to trade the hottest instruments in the financial markets with first class support 24/7.
        To get started trading in your real account, select the type of account and click “Open Account.” Once you are ready, you will need to upload your verification documents and make an initial deposit to earn profits.</p>
      <h3>Trading Tools</h3>
      <p>Take your analysis to the next level with our pro tools. Enjoy deep charts and live news.</p>
      <h3>Liquidity</h3>
      <p>Whichever products you trade, our broker has deep liquidity. Trade big in all assets.</p>
      <h3>Deposit</h3>
      <p>Take advantage of our easy e-wallet and crypto deposit methods. Funds reflect instantly.</p>
    </div>
    <h1 className='indent__title_auxilary'>Account Types</h1>
    <p>Clients of The Intervide come from many different backgrounds and experience levels. Select the account type that is best for you.</p>
    <div className="typesCardBox indent__title_auxilary">
      {arrTypes.map((i, index)=>
          <div className="typeCard" key={index + i.title}>
            <div className="typeCard__titleBox">
              <h2>
                {i.title}
              </h2>
            </div> 
            <hr />
            <ul>
              <li><div className="checkmark checkmark--active" />Leverage: {i.leverage}</li>
              <li><div className={`checkmark ${i.accountManager == "YES"? "checkmark--active" : "checkmark--inactive"}` }/>Personal account manager: {i.accountManager}</li>
              <li><div className={`checkmark ${i.swap == "NO"? "checkmark--inactive" : "checkmark--active"}` } />Swap discount: {i.swap}</li>
              <li><div className="checkmark checkmark--active" />Fifth decimal: {i.decimal}</li>
              <li><div className={`checkmark ${i.VPS == "YES"? "checkmark--active" : "checkmark--inactive"}` } />Free VPS: {i.VPS}</li>
              <li><div className={`checkmark ${i.news == "YES"? "checkmark--active" : "checkmark--inactive"}` } />Customized investment news: {i.news}</li>
            </ul>
        </div>
      )}
      
    </div>
    {/* <div className="tableBlock indent__title_auxilary">
      <table>
        <thead>
          <tr>
            <th>Account types</th>
            <th>Silver </th>
            <th>Gold </th>
            <th>Platinum </th>
          </tr>
        </thead>
        <tbody>
          {arrBody.map((i, index) => 
          <tr>
            <td>{i.account}</td>
            <td>{i.basic}</td>
            <td>{i.gold}</td>
            <td>{i.platinum}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div> */}

    <div className="indent__title_auxilary">
      <h1 className='indent__title_auxilary'>Account verification</h1>
      <p className="indent__title_auxilary">Our secure account verification has you trading fast. Once verified, clients can choose from our easy deposit methods including e-wallet and cryptocurrency. In order to verify the identity of the client, we ask you to provide certain documents in accordance with the international KYC policy.
        You are required to provide us with proof of identity (POI) and proof of residence (POR) and complete a quick questionnaire (Appropriateness test and Economic profile).  You can upload the documents by clicking below and uploading them on our system.
      </p>
      <h3>KYC check</h3>
      <p>Our identification process ensures your account protection. Our KYC check is secure and fast.</p>
      <h3>Encrypted</h3>
      <p>All client information provided is fully encrypted and safe. Account protection is our #1 priority.</p>
      <h3>Security</h3>
      <p>All funds deposited are held in segregated accounts. Our broker works with top tier banks. </p>
    </div>

    <div>
      <h1 className='indent__title_auxilary'>Deposit</h1>
      <p className="indent__title_auxilary">Clients of The Intervide have multiple methods of deposit. E-wallet and cryptocurrency deposits arrive into your account instantly. Depositing funds with The Intervide is both safe and convenient. Your funds are kept in segregated accounts, ensuring your money is always protected.</p>
      <h3>One single account</h3>
      <p>Trade a range of products, including Forex, Cryptocurrencies, Commodities, Indices, and Metals with Intervide.</p>
      <h3>Outstanding support</h3>
      <p>Our dedicated team of professionals is always at your service, available 24 hours a day, 5 days a week to address your needs.</p>
      <h3>Safe & segregated funding</h3>
      <p>Your funds are safe in a segregated account. We base our work on transparency, honesty, and professionalism.</p>
    </div>

    <div>
      <h1 className='indent__title_auxilary'>Withdrawals</h1>
      <p className="indent__title_auxilary">Deposits and withdrawals are regulated by this policy and the generally applicable terms and conditions as well. According to generally accepted AML rules and regulations, withdrawals should only be made through the same bank account or Credit/Debit card that the customer used to deposit funds.</p>
      <h3>Two-way crypto</h3>
      <p>With us depositing and withdrawing by crypto is fast and easy.</p>
      <h3>Security</h3>
      <p>Deposits are segregated from operational funds. Security of client funds is our top priority.</p>
      <h3>Compliance</h3>
      <p>When withdrawing our compliance team follows strict protocols.</p>
    </div>
    
  </div>
 ) 
}