import React, { useEffect, useRef, useState } from "react";
import './tradingPage.scss';



import trade from './assets/trade.svg'
import choose from './assets/search.svg'
import simple from './assets/website-4283.svg'
import over from './assets/winner-1315.svg'
import support from './assets/24-7-support-10895.svg'
import trusted from './assets/digital-wallet-9890.svg'
import notebook from './assets/10.png'
import phonebook from './assets/20.png'
import mobile from './assets/30.png'
import TradingSwiper from "./components/swiper/swiper.jsx";
import { tradingLink, loginLink } from '../../constants/variables.js'
import { useSelector } from 'react-redux';
import MayInteresting from "../../components/mayInteresting/mayInteresting";
import appl from './assets/btns/btn-app-store.svg';
import direct from './assets/btns/apk-download-badge.webp';
import android from './assets/btns/btn-google-play-high-res.png';
import collapse from './assets/collapse-arrow.svg';


import langTokens from '../../languages/en.json';   // for new implementation 


const Accordion = (props) => {
  const [state, setState] = useState(false);
  const [heightElement, setheightElement] = useState(0);
  const accordionRef = useRef();


  useEffect(() => {
      setheightElement(accordionRef.current.children[0].offsetHeight);
  }, [setheightElement, state]);

  return (
      <div className={`accordion__row${state ? " open" : ""}`}>
          <div className="accordion__btn" onClick={() => setState(!state)}>
              <h3>{props.title}</h3>
              <img src={collapse} alt="" className='accordion__arrow' width="15px" height="15px"/>
          </div>
          <div className="accordion__collapse" ref={accordionRef} style={state ? { height: heightElement + "px" } : { height: 0 }}>
              <p>{props.text}</p>
          </div>
      </div >
  )
}

const Trading = () => {
    // const langTokens = useSelector((state) => state.language.data);
    const appStorePath = 'https://apps.apple.com/de/app/pro-trading-app/id6447182562';
    let arrFAQ = [
      {title: "Am I required to download anything to access WebTrader?", text: "-Not at all. WebTrader can be accessed exclusively from any web browser that is connected to the internet. No downloads necessary."},
      {title: "Can I run my specific trading indicators on WebTrader?", text: "-Yes. WebTrader offers 30+ indicators and over nine time frames to run your technical analysis so you should be fully covered."},
      {title: "Do you have Altcoins available on WebTrader?", text: "-Yes. All cryptocurrencies like Bitcoin, Ethereum and Ripple are available to trade on WebTrader along with hard-to-find Altcoins."},
      {title: "Can I insert objects onto charts on WebTrader?", text: "-Yes. Click the ‘Insert’ menu on WebTrader and hover over the option for ‘Objects’. Select the graphical object you would like to insert onto the chart."},
      {title: "If my open positions are not updating when logged into WebTrader what should I do?", text: "-This is most likely an internet connection issue. Please check your internet connection or attempt to connect to WebTrader from a different internet connection."},
      {title: "Is your broker’s full range of financial products available on WebTrader?", text: "-Yes. You can trade our wide range of forex pairs, indices, commodities, stocks and cryptocurrencies all on WebTrader with no restrictions."},
      {title: "If I place a trade on the Desktop Terminal, will it appear on WebTrader?", text: "-Yes. Positions placed on your live trading account will appear on WebTrader regardless of where the trade initiated."},
      {title: "Does WebTrader allow me view charts?", text: "-Absolutely. You can pull up charts via WebTrader including all instruments, time frames and desired technical indicators."},
      {title: "How can I be sure my data is safe with WebTrader?", text: "-All personal and trading data transmitted is completely secure and encrypted which guarantees the safety of your data"},
    ]
    return (
        <>

            <div className="container trading">
                <div className="trading__header row">
                    <div className="trading__header__text col-lg-6">
                        <h1 className="indent__title_auxilary">
                            {/* {langTokens.TRADIN_PAGE_TITTLE} */}
                            Platform for Desktop
                        </h1>
                        <p>
                            {/* {langTokens.TRADING_PAGE_EXPIRIENCE} */}
                          The Intervide Desktop Terminal remains an undisputed leader among world traders. It has all the tools needed by both novice and experienced traders. Clients of The Intervide can trade on our secure Desktop Terminal around the clock.
                          An advanced user-friendly platform is necessary for today’s modern trader. With fast execution and transparent pricing, you can trade various products, including currencies, indices, stocks, cryptocurrencies, and commodities.
                        </p>
                        <ul className="indent__list">
                          <li><h5>Instantaneous execution</h5><p>All trades on Desktop Terminal are instantaneously executed. Trade confirmation comes in milliseconds.</p></li>
                          <li><h5>Secure login credentials</h5><p>Clients of The Intervide login with unique and encrypted trading details. Account security is fully guaranteed.</p></li>
                          <li><h5>Expert advisors</h5><p>Run all expert advisors including scalping EAs. Our liquidity pools are deep in all products 24 hours a day.</p></li>
                        </ul>
                        {/* <a className="btn btn--create lg-btn" href={loginLink}>{langTokens.TRADING_BTN_CREATE}</a> */}

                        {/* <div className="btn_group row"> */}
                        {/* <a className="btn btn--demo" href={tradingLink}>{langTokens.TRADING_BTN_TRY}</a> */}
                        {/* </div> */}
                    </div>
                    <div className="trading__header__img col-lg-6 d-sm-none d-md-block">
                        <img src={phonebook} alt="" width="700px" height="525px" />
                    </div>
                </div>

                {/* <div className="trading__platform">
                    <div className="indent__title">
                        <h2>{langTokens.TRADING_PLATFORM_TITTLE}</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <img className="trading__platform__img indent__svg_icon trading__platform__auxilary_img" src={trade} alt="" width="72px" height="82px" />
                            <div className="card_title_min"><h3>{langTokens.TRADING_PLATFORM_TRADE_TITTLE}</h3></div>
                            <div><p className="">{langTokens.TRADING_PLATFORM_TRADE_MAIN}</p></div>
                        </div>
                        <div className="col-md-4">
                            <img className="trading__platform__img indent__svg_icon" src={choose} alt="" width="72px" height="82px" />
                            <div className="card_title_min"><h3>{langTokens.TRADING_PLATFORM_CHOOSE_TITTLE}</h3></div>
                            <div><p>{langTokens.TRADING_PLATFORM_CHOOSE_MAIN}</p></div>
                        </div>
                        <div className="col-md-4">
                            <img className="trading__platform__img indent__svg_icon" src={simple} alt="" width="72px" height="82px" />
                            <div className="card_title_min"><h3>{langTokens.TRADING_PLATFORM_SIMPLE_TITTLE}</h3></div>
                            <div><p className="">{langTokens.TRADING_PLATFORM_SIMPLE_MAIN}</p></div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="trading__platform__mobile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={mobile} alt="" className="desk_img" width="700px" height="478px" />
                        </div>
                        <div className="col-lg-6 trading__platform__mobile__text ">
                            <h2 className="indent__title_auxilary">Platform for Mobile</h2>
                            <p className="auxilary indent__text">
                              Feel the genuinely mobile trading experience with our mobile app for Android devices. Never miss an investment opportunity!
                              The mobile versions’ functionality includes interactive quote charts, a complete set of trading orders, and the most popular analytical tools. You can track the history of trades and buy and sell financial instruments with a single tap.
                            </p>
                            {/* <img src={phone} alt="" className="phone_img d-sm-none d-md-block"/> */}
                            {/* <div className="btn_group row" style={{'justifyContent':'center'}}> */}
                            {/* <a className="btn btn--download" href="https://trade.intervide.com/en/sign/up">{langTokens.TRADING_PLATFORM_MOBILE_BTN_MOBILE}</a> */}
                            {/* </div> */}
                            <ul className="indent__list">
                              <li><h5>Deposit and withdraw</h5><p>Sending funds to your account from our handy Mobile App takes just a few minutes.</p></li>
                              <li><h5>All order types</h5><p>Clients can set stop, limit and entry orders with precision directly from Mobile App.</p></li>
                              <li><h5>Advanced charts</h5><p>Analyze charts ad perform deep technical studies from Mobile App 24 hours a day.</p></li>
                            </ul>
                            <div className="row">
                                {/* <div className="col-6 col-md-4 trading__platform__mobile__centered">
                                    <a href={appStorePath} target="_blanc"><img src={appl} alt='' className="trading__download-btn" width={"150px"} height={"50px"} /></a>
                                </div> */}
                                {/* <div className="col-6 col-md-4 trading__platform__mobile__centered">
                                    <img src={android} alt='' className="trading__download-btn" width={"150px"} height={"50px"}/>
                                </div>
                                <div className="trading__mobile-center col-md-4 trading__platform__mobile__centered">
                                    <a href={process.env.PUBLIC_URL + `/assets/documents/truenorthtraders.apk`}><img src={direct} alt='' className="trading__download-btn" width={"150px"} height={"50px"}/></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="trading__platform__web trading__platform__web__liner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 trading__platform__web__text">
                            <ul className="list-unstyled nav">
                                <li className="nav-item indent__title_auxilary"><h3>Webtrader</h3></li>
                            </ul>
                            <p className="indent__text">
                            With the web version of our trading platform, you will rapidly gain experience in trading in financial markets. This platform has been chosen by thousands of traders around the world due to its numerous advantages.
                            With the The Intervide WebTrader you can trade anywhere at any time. Enter, exit and adjust trades on the go from any browser. Trade like a true pro with access to your positions.</p>
                            {/* <div className="btn_group row" style={{padding: 0}}> */}
                            {/* <a className="btn btn--create lg-btn" href={tradingLink}>{langTokens.TRADING_BTN_CREATE}</a> */}
                            {/* <a className="btn btn--demo" href={loginLink}>{langTokens.TRADING_BTN_TRY}</a> */}
                            {/* </div> */}
                            <ul className="indent__list">
                              <li><h5>Deep analytical tools</h5><p>From WebTrader access advanced charting, real-time news and trading volume data.</p></li>
                              <li><h5>Top tier banks</h5><p>Deposits with The Intervide are held only in top tier banks. Safety of client funds is our top priority.</p></li>
                              <li><h5>Mobile access</h5><p>Trade 24 hours a day from any browser with WebTrader. You are in control from any location.</p></li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <img src={notebook} alt="" className="trading__platform__web__img" width="561px" height="383px" />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="trading__platform__md5 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="trading__platform__md5__title">
                                Our own Intervide MT5 Terminal
                            </h3>
                            <div className="trading__platform__md5__text">
                                MT5 Terminal Window
                            </div>
                            <a className="btn btn--create lg-btn" href="https://8021d35e-a6b3-4144-95cf-3b859085f4fc.usrfiles.com/archives/8021d3_d2c76ae1a6914ae8ad4dc9eb82154c0a.zip ">Download</a>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="trading__platform__discover container">
              <h2 className="indent__title_auxilary">Trading FAQs</h2>
              <div className="card platformFAQ">
                {arrFAQ.map((i, index) => <Accordion title={i.title} text={i.text} key={index} />)}
              </div>
                {/* <h2>{langTokens.TRADING_PLATFORM_DISCOVER}</h2>
                <div>
                    <TradingSwiper />
                </div> */}

            </div>

            <div className="trading__xtb">
                <div className="container">
                    <div className="indent__title_auxilary">
                        <h2>Currency pairs</h2>
                    </div>
                    <p className="indent__title_auxilary">The Forex market offers incredible opportunities daily with over 60 FX pairs. With world economies in flux, currencies are really moving. Buy and sell major currencies like EUR/USD and USD/JPY instantly. Leverage up to 500:1 and never pay commissions with Intervide.To succeed in forex trading, traders must have a deep understanding of the economic and geopolitical events that impact exchange rates and a solid trading strategy.</p>
                    <h3>Variety of currency pairs</h3>
                    <p className="indent__title_auxilary">Trade a wide selection of currency pairs on Intervide, including major, minor, and exotic pairs.</p>
                    <h3>Crypto Currencies</h3>
                    <p className="indent__title_auxilary">Cryptocurrency, a digital or virtual currency secured through cryptography, has been making waves in the financial world. Unlike traditional currencies, it operates without the control of a central bank. It is decentralized, making it a popular investment option for those seeking an alternative to traditional finance.Traders looking for double digit returns each month are focusing on cryptos to hit their goals. Win big on crypto trades including on weekends.</p>
                    <h3>Indices</h3>
                    <p className="indent__title_auxilary">The DOW, S&P 500 and DAX 30 are some of the most heavily traded indices in the world.  Trading Index CFDS brings clients ultra-low spreads and high leverage 24/5.Indices are more profitable assets than individual stocks. Investing in an index rather than a particular stock is an excellent way to reduce risk and increase profit. Indices can be traded with meager initial capital.</p>
                    <h3>Energies</h3>
                    <p className="indent__title_auxilary">The energy markets are among the most followed in the world. Traders benefit the most from the news releases on energies. Clients of The Intervide can trade Energy CFDs 24 hours a day on high leverage.Since the production and supply of these assets can be accurately predicted, they are used to assess the state of the global economy. In particular, crude oils are often looked at to determine where the economy is headed by observing total-worldwide demand for these products.</p>
                    <h3>Futures</h3>
                    <p className="indent__title_auxilary">The Intervide offers Futures CFDs from the Chicago Board of Trade (CBOT) and Chicago Mercantile Exchange (CME) with no exchange fees.
                    Trading futures with our broker is more attractive since you enjoy high leverage and no commissions. Sign up with our broker today.</p>
                    <h3>Shares</h3>
                    <p className="indent__title_auxilary">Share trading has long captured the attention of traders globally with double digit return opportunities being presented weekly.</p>
                    <h3>Pick Your Trades</h3>
                    <p className="indent__title_auxilary">Trade Share CFDs with Intervide and enjoy high leverage and zero commissions around the clock. Capitalize on big moves in shares up or down.</p>
                    {/* <div className="row">
                        <div className="col-md-4">
                            <img className="trading__xtb__img indent__svg_icon trading__xtb__img__first" src={over} alt="" width="72px" height="82px" />
                            <h3>{langTokens.TRADING_PLATFORM_LIST_ITEM_1_TITTLE}</h3>
                            <p>{langTokens.TRADING_PLATFORM_LIST_ITEM_1_MAIN}</p>
                        </div>
                        <div className="col-md-4">
                            <img className="trading__xtb__img indent__svg_icon" src={support} alt="" width="72px" height="82px" />
                            <div>
                                <h3>{langTokens.TRADING_PLATFORM_LIST_ITEM_2_TITTLE}</h3>
                            </div>
                            <p>{langTokens.TRADING_PLATFORM_LIST_ITEM_2_MAIN}</p>
                        </div>
                        <div className="col-md-4">
                            <img className="trading__xtb__img indent__svg_icon" src={trusted} alt="" width="72px" height="82px" />
                            <h3>{langTokens.TRADING_PLATFORM_LIST_ITEM_3_TITTLE}</h3>
                            <p>{langTokens.TRADING_PLATFORM_LIST_ITEM_3_MAIN}</p>
                        </div>
                    </div> */}

                </div>
            </div>
            <div>
                <MayInteresting title="Trading" />
            </div>
        </>
    )
}

export default Trading;