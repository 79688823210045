import React from "react";
import "./accountPage.scss";
import { useSelector } from 'react-redux';


export default function AccountDeposit() {
  const langTokens = useSelector((state) => state.language.data);

  return (
    <div className="accountPage container">
      <div>
        <h1 className='indent__title_auxilary'>{langTokens.ACCOUNT_DEPOSITE_TITLE}</h1>
        <p className="indent__title_auxilary">{langTokens.ACCOUNT_DEPOSITE_TEXT}</p>
        <h3>{langTokens.ACCOUNT_DEPOSITE_SINGL}</h3>
        <p>{langTokens.ACCOUNT_DEPOSITE_SINGL_TEXT}</p>
        <h3>{langTokens.ACCOUNT_DEPOSITE_SUP}</h3>
        <p>{langTokens.ACCOUNT_DEPOSITE_SUP_TEXT}</p>
        <h3>{langTokens.ACCOUNT_DEPOSITE_SAFE}</h3>
        <p>{langTokens.ACCOUNT_DEPOSITE_SAFE_TEXT}</p>
      </div>
    </div>

  )
}