import React, { useState, useEffect, useRef } from 'react';
import './header.scss';
import '../../app.scss';
import { tradingLink, loginLink } from '../../constants/variables';
import Burger from './components/burgermenu/burgermenu';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, changeLanguage } from '../../core/languageSlice.js';
import Cookies from 'universal-cookie';
import Logo from '../../assets/Intervide_logo.svg';
import engIco from './assets/eng.svg';
import rusIco from './assets/rus.svg';
import plIco from "./assets/pl.svg"
import useWidth from '../../hooks/screenWidth';
import en from '../../languages/en.json';
import pl from '../../languages/pl.json';

const cookies = new Cookies();

const languages = [
    { inner: "", short: "", img: {} },
    { inner: "English", short: "en", img: engIco },
    { inner: "Polski", short: "pl", img: plIco }
]

const ChangeLang = () => {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(1);
  const selectedLang = useSelector((state) => state.language.selected);

  const [menuStatus, setMenuStatus] = useState(false);
  const handleClickOpen = () => {
      setMenuStatus(!menuStatus);
  };

  const handleClickChange = (index) => {
      const selectedLang = languages[index].short;
      dispatch(selectLanguage(selectedLang));
      cookies.set('language', selectedLang, { path: '/' });

      // Здесь больше не вызывается languageRequest
      let newLanguage;
      switch (selectedLang) {
          case 'pl':
              newLanguage = pl;
              break;
          case 'en':
          default:
              newLanguage = en;
              break;
      }

      dispatch(changeLanguage(newLanguage));
  };

  const menuBtn = useRef(null);

  const width = useWidth(); // предполагаем, что useWidth — это пользовательский хук для получения ширины экрана

  useEffect(() => {
      for (let i = 0; i < languages.length; i++) {
          if (Object.values(languages[i]).includes(selectedLang)) {
              setIndex(i);
              break;
          }
      }

      const onClick = (e) => menuBtn.current.contains(e.target) || setMenuStatus(!menuStatus);

      if (menuStatus) {
          document.addEventListener('click', onClick);
          return () => document.removeEventListener('click', onClick);
      }
  }, [index, selectedLang, menuStatus]);

  return (
      width > 1200 ?
          <>
              <div className="header__languages-selected" ref={menuBtn} onClick={handleClickOpen}>
                  <img src={languages[index].img} alt="" width="5px" height="5px" />
                  {languages[index].short}
              </div>
              <div className={`languages__menu${menuStatus ? " languages__menu--opened" : ''}`}>
                  <ul className='languages__nav'>
                      {languages.slice(1).map((i, index) => {
                          return <li className='languages__link' key={index} onClick={() => {
                              handleClickChange(index + 1);
                          }}>
                              <img src={i.img} alt="" width="5px" height="5px" />
                              {i.inner}
                          </li>
                      })}
                  </ul>
              </div>
          </>
          :
          <ul className='languages__nav'>
              {languages.slice(1).map((i, index) => {
                  return <li className='languages__link' key={index} onClick={() => {
                      handleClickChange(index + 1);
                  }}>
                      <img src={i.img} alt="" width="5px" height="5px" />
                      {i.inner}
                  </li>
              })}
          </ul>
  )
};

const Markets = () => {
    const langTokens = useSelector((state) => state.language.data);

    const markets = [
        { inner: "", href: "" },
        { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST, href: "/account-real" },
        { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST2, href: "/account-types" },
        { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST3, href: "/account-verification" },
        { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST4, href: "/account-deposit" },
        { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST5, href: "/account-withdrawals" },
    ]
    const [menuStatus, setMenuStatus] = useState(false);
    const handleClickOpen = () => {
        setMenuStatus(!menuStatus);
    };

    const menuBtn = useRef(null);
    useEffect(() => {

        const onClick = e => menuBtn.current.contains(e.target) || setMenuStatus(!menuStatus);

        if (menuStatus) {
            document.addEventListener('click', onClick);
            return () => document.removeEventListener('click', onClick);
        }

    }, [menuStatus])
    return (
        <>
            <div className="header__languages-selected drop" ref={menuBtn} onClick={handleClickOpen}>
              {langTokens.HEADER_INTERVIDE_ACCOUNTS}
            </div>
            <div className={`languages__menu${menuStatus ? " languages__menu--opened" : ''}`}>
                <ul className='languages__nav'>
                    {markets.slice(1).map((i, index) => {
                        return <li className='languages__link' key={index}>
                            <a href={i.href} className="drop">{i.inner}</a>
                        </li>
                    })}
                </ul>
            </div>
        </>
    )
}

const MarketAnalysis = () => {
    const langTokens = useSelector((state) => state.language.data);

    const MarketAnalysis = [
        { inner: "", href: "" },
        { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST, href: "/platform-desktop" },
        { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST2, href: "/platform-mobile" },
        { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST3, href: "/platform-webtrader" },
        { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST4, href: "/platform-faq" },

    ]
    const [menuStatus, setMenuStatus] = useState(false);
    const handleClickOpen = () => {
        setMenuStatus(!menuStatus);
    };

    const menuBtn = useRef(null);

    useEffect(() => {
        const onClick = e => menuBtn.current.contains(e.target) || setMenuStatus(!menuStatus);

        if (menuStatus) {
            document.addEventListener('click', onClick);
            return () => document.removeEventListener('click', onClick);
        }

    }, [menuStatus])
    return (
        <>
            <div className="header__languages-selected drop" ref={menuBtn} onClick={handleClickOpen}>
                {langTokens.HEADER_INTERVIDE_PLATFORM}
            </div>
            <div className={`languages__menu${menuStatus ? " languages__menu--opened" : ''}`}>
                <ul className='languages__nav'>
                    {MarketAnalysis.slice(1).map((i, index) => {
                        return <li className='languages__link' key={index}>
                            <a href={i.href} className="drop">{i.inner}</a>
                        </li>
                    })}
                </ul>
            </div>
        </>
    )
}

const Trading = () => {
  const langTokens = useSelector((state) => state.language.data);

  const MarketAnalysis = [
      { inner: "", href: "" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST, href: "/trading-crypto-currencies" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST2, href: "/trading-currency-pair" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST3, href: "/trading-energies" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST4, href: "/trading-futures" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST5, href: "/trading-indices" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST6, href: "/trading-metals" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST7, href: "/trading-shares" },
  ]
  const [menuStatus, setMenuStatus] = useState(false);
  const handleClickOpen = () => {
      setMenuStatus(!menuStatus);
  };

  const menuBtn = useRef(null);

  useEffect(() => {
      const onClick = e => menuBtn.current.contains(e.target) || setMenuStatus(!menuStatus);

      if (menuStatus) {
          document.addEventListener('click', onClick);
          return () => document.removeEventListener('click', onClick);
      }

  }, [menuStatus])
  return (
      <>
          <div className="header__languages-selected drop" ref={menuBtn} onClick={handleClickOpen}>
              {langTokens.HEADER_INTERVIDE_TRADING}
          </div>
          <div className={`languages__menu${menuStatus ? " languages__menu--opened" : ''}`}>
              <ul className='languages__nav'>
                  {MarketAnalysis.slice(1).map((i, index) => {
                      return <li className='languages__link' key={index}>
                          <a href={i.href} className="drop">{i.inner}</a>
                      </li>
                  })}
              </ul>
          </div>
      </>
  )
}

const ContactUs = () => {
  const langTokens = useSelector((state) => state.language.data);

  const MarketAnalysis = [
      { inner: "", href: "" },
      { inner: langTokens.HEADER_INTERVIDE_COMPANY_LIST, href: "/Intervide-support" },
      { inner: langTokens.HEADER_INTERVIDE_COMPANY_LIST2, href: "/partnership" },
      { inner: langTokens.HEADER_INTERVIDE_COMPANY_LIST3, href: "/contact-us" },
  ]
  const [menuStatus, setMenuStatus] = useState(false);
  const handleClickOpen = () => {
      setMenuStatus(!menuStatus);
  };

  const menuBtn = useRef(null);

  useEffect(() => {
      const onClick = e => menuBtn.current.contains(e.target) || setMenuStatus(!menuStatus);

      if (menuStatus) {
          document.addEventListener('click', onClick);
          return () => document.removeEventListener('click', onClick);
      }

  }, [menuStatus])
  return (
      <>
          <div className="header__languages-selected drop" ref={menuBtn} onClick={handleClickOpen}>
              {langTokens.HEADER_INTERVIDE_COMPANY}
          </div>
          <div className={`languages__menu${menuStatus ? " languages__menu--opened" : ''}`}>
              <ul className='languages__nav'>
                  {MarketAnalysis.slice(1).map((i, index) => {
                      return <li className='languages__link' key={index}>
                          <a href={i.href} className="drop">{i.inner}</a>
                      </li>
                  })}
              </ul>
          </div>
      </>
  )
}

const Header = () => {
    const langTokens = useSelector((state) => state.language.data);
    const selectedLang = useSelector((state) => state.language.selected);
    const navLinks = [
      // {inner: "Company", href: "/about-us"},

        // { inner: langTokens.HEAD_NAVLINK1, href: "/trading-platforms" },
        // { inner: langTokens.HEAD_NAVLINK2, href: "/market-news" },
        // { inner: langTokens.HEAD_NAVLINK6, href: "/contacts"}
    ]
    const burgerLink = [
      { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST, href: "/account-real" },
      { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST2, href: "/account-types" },
      { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST3, href: "/account-verification" },
      { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST4, href: "/account-deposit" },
      { inner: langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST5, href: "/account-withdrawals" },
      { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST, href: "/platform-desktop" },
      { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST2, href: "/platform-mobile" },
      { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST3, href: "/platform-webtrader" },
      { inner: langTokens.HEADER_INTERVIDE_PLATFORM_LIST4, href: "/platform-faq" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST, href: "/trading-crypto-currencies" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST2, href: "/trading-currency-pair" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST3, href: "/trading-energies" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST4, href: "/trading-futures" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST5, href: "/trading-indices" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST6, href: "/trading-metals" },
      { inner: langTokens.HEADER_INTERVIDE_TRADING_LIST7, href: "/trading-shares" },
      { inner: langTokens.HEADER_INTERVIDE_COMPANY_LIST, href: "/Intervide-support" },
      { inner: langTokens.HEADER_INTERVIDE_COMPANY_LIST2, href: "/partnership" },
      { inner: langTokens.HEADER_INTERVIDE_COMPANY_LIST3, href: "/contact-us" },
    ]
    return (
        <header>
            <div className="header__wrapper">
                <div className="container d-flex justify-content-between align-items-center" >
                    <a className="d-inline-block" href="/" style={{ marginRight: '10px' }}>
                        <img src={Logo} alt="Intervide" width="180px" height="44px" className="header__logo" />
                    </a>
                    <div className="d-none d-xl-flex header__open-account" style={{ marginRight: '-80px' }}>
                        <Markets />
                    </div>
                    <div className="d-none d-xl-flex header__open-account" style={{ marginRight: '-80px' }}>
                        <MarketAnalysis />
                    </div>
                    <div className="d-none d-xl-flex header__open-account" style={{ marginRight: '-80px' }}>
                        <Trading />
                    </div>
                    <div className="d-none d-xl-flex header__open-account" style={{ marginRight: '-80px' }}>
                        <ContactUs />
                    </div>
                    {/* <div className="d-none d-xl-inline-block ">
                        {navLinks.map((i, index) =>
                            <div className="header__link" style={{ marginRight: '40px' }} key={index}><a href={i.href}>{i.inner}</a></div>
                        )}
                    </div> */}
                    <div className="d-flex d-xl-none align-items-center">
                        <Burger>
                            {burgerLink.map((i, index) =>
                                <li className="burger__link" key={index}><a href={i.href}>{i.inner}</a></li>
                            )}
                            <div className={`overall-header-buttons`}>
                                <a href={tradingLink} className="burger-header__btn burger-header__btn--demo md-btn">{langTokens.BTN_DEMO}</a>
                                <a href={loginLink} className="burger-header__btn burger-header__btn--real md-btn">{langTokens.BTN_CREATE_ACCOUNT}</a>
                            </div>
                            <div className='burger-header__btn__languages'>
                                <ChangeLang />
                            </div>
                        </Burger>
                    </div>
                    {/* <div className="d-none d-xl-flex header__open-account" style={{ marginRight: '-110px' }}>
                        <MarketAnalysis />
                    </div> */}
                    <div className="d-none d-xl-inline-block ">
                      {/* <div className='header__link' style={{ marginRight: '-110px' }}>
                        <a href="/account"> Accounts Types</a>
                      </div> */}
                    </div>
                    {/* <div className="d-none d-xl-flex header__link" style={{ marginRight: '-110px' }}><a href="/contact-us">{langTokens.HEAD_NAVLINK6}</a></div> */}
                    <div className="d-none d-xl-flex header__open-account">
                        <ChangeLang />
                        <div className="header__btn header__btn--demo ">
                            <a href={tradingLink} className="sm-btn sm-btn--header">
                                <span>{langTokens.BTN_DEMO}</span>
                            </a>
                        </div>
                        <div className="header__btn header__btn--real">
                            <a href="https://trade.intervide.com/en/sign/up" className="md-btn md-btn--header">
                                <span>{langTokens.BTN_CREATE_ACCOUNT}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    )
};

export default Header;