import React, { lazy, Suspense } from 'react';
import './app.scss';
import './assets/variables.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header, Footer } from './components/components.js';
import RiskBanner from './components/riskBanner/riskBanner';
import { getLanguage } from './actions/actions';
import Cookies from 'universal-cookie';
import { Main } from './pages/pages.js';

const cookies = new Cookies();

const initialState = () => {
    const remembered = cookies.get('language');
    // if (remembered !== "en") {
    getLanguage(remembered);
    // }
}

const TradingLazy = lazy(() => import('./pages/PlatformPage/tradingPage.jsx'));
const StocksLazy = lazy(() => import('./pages/stocksPage/stocksPage.jsx'));
const PostLazy = lazy(() => import('./pages/postPage/postPage.jsx'));
const Newslazy = lazy(() => import('./pages/newsPage/newsPage.jsx'));
// const ContactsLazy = lazy(() => import('./pages/contactPage/contactPage.jsx'));
const CryptoLazy = lazy(() => import('./pages/cryptoPage/cryptoPage.jsx'));
const ForexLazy = lazy(() => import('./pages/forexPage/forexPage.jsx'));
const CalendarLazy = lazy(() => import('./pages/calendarPage/calendarPage.jsx'));
const AssetsLazy = lazy(() => import('./pages/assetsPage/assetsPage.jsx'));
const DownloadLazy = lazy(() => import('./pages/downloadPage/downloadPage.jsx'));
const ContractLazy = lazy(() => import('./pages/contractPage/contractPage.jsx'));
const ConditionsLazy = lazy(() => import('./pages/conditionsPage/conditionsPage.jsx'));
const CommoditiesLazy = lazy(() => import('./pages/commoditiesPage/commoditiesPage.jsx'));
const DepositsLazy = lazy(() => import('./pages/depositPage/depositPage.jsx'));
const RegisterLazy = lazy(() => import('./pages/registerPage/registerPage.jsx'));
const AccountLazy = lazy(() => import('./pages/accountPage/accountPage.jsx'));
const LegalLazy = lazy(() => import('./pages/LegalPage/LegalPage.jsx'));
const AccountWithdrawals = lazy(() => import('./pages/accountPage/accountWithdrawals.jsx'));
const AccountDeposit = lazy(() => import('./pages/accountPage/accountDeposit.jsx')) 
const AccountVerification = lazy(() => import('./pages/accountPage/accountVer.jsx'))
const RealAccount = lazy(() => import('./pages/accountPage/realAccount.jsx'))
const AccountTypes = lazy(() => import('./pages/accountPage/accountTypes.jsx'))
const TradingFaq = lazy(() => import('./pages/PlatformPage/faq.jsx'))
const TradingWebtrader = lazy(() => import('./pages/PlatformPage/webtrader.jsx'))
const TradingMobile = lazy(() => import('./pages/PlatformPage/mobilePlatform.jsx'))
const TradingDesktop = lazy(() => import('./pages/PlatformPage/desktopPlatform.jsx'))
const CryptoCurrencies = lazy(() => import('./pages/tradingPage/CryptoCurrencies.jsx'))
const CurrencyPair = lazy(() => import('./pages/tradingPage/CurrencyPair.jsx'))
const Energies = lazy(() => import('./pages/tradingPage/Energies.jsx'))
const Futures = lazy(() => import('./pages/tradingPage/Futures.jsx'))
const Indices = lazy(() => import('./pages/tradingPage/Indices.jsx'))
const Metals = lazy(() => import('./pages/tradingPage/Metals.jsx'))
const Shares = lazy(() => import('./pages/tradingPage/Shares.jsx'))
const Partnership = lazy(() => import('./pages/aboutUsPage/partnership.jsx'))
const IntervideSupport = lazy(() => import('./pages/aboutUsPage/IntervideSupport.jsx'))
const Contact = lazy(() => import('./pages/aboutUsPage/contactUs.jsx'))



const App = () => {
    initialState();
    return (
        <div className="App">
            <Header />
            <div className="main">
                <BrowserRouter>
                    <Suspense fallback={<div сlassname="preswipeloader" />}>
                        <Routes>
                            <Route path="/" element={<Main />} />
                            <Route path="/trading-platforms" element={<TradingLazy />} />
                            <Route path='/account-withdrawals' element={<AccountWithdrawals />} />
                            <Route path='/account-deposit' element={<AccountDeposit />} />
                            <Route path='/account-verification' element={<AccountVerification />} />
                            <Route path='/account-real' element={<RealAccount />} />
                            <Route path='/account-types' element={<AccountTypes />} />
                            <Route path='/platform-faq' element={<TradingFaq />} />
                            <Route path='/platform-webtrader' element={<TradingWebtrader />} />
                            <Route path='/platform-mobile' element={<TradingMobile />} />
                            <Route path='/platform-desktop' element={<TradingDesktop />} />
                            <Route path='/trading-crypto-currencies' element={<CryptoCurrencies />} />
                            <Route path='/trading-currency-pair' element={<CurrencyPair />} />
                            <Route path='/trading-energies' element={<Energies />} />
                            <Route path='/trading-futures' element={<Futures />} />
                            <Route path='/trading-indices' element={<Indices />} />
                            <Route path='/trading-metals' element={<Metals />} />
                            <Route path='/trading-shares' element={<Shares />} />
                            <Route path='/Intervide-support' element={<IntervideSupport />} />
                            <Route path='/partnership' element={<Partnership />} />
                            <Route path='/contact-us' element={<Contact />} />

                            {/* <Route path='/cfd' element={<StocksLazy />} /> */}
                            <Route path='/market-news/:id' element={<PostLazy />} />
                            <Route path='/market-news' element={<Newslazy />} />
                            {/* <Route path='/contact-us' element={<ContactsLazy />} /> */}
                            {/* <Route path='/crypto' element={<CryptoLazy />} /> */}
                            {/* <Route path='/forex' element={<ForexLazy />} /> */}
                            {/* <Route path='/calendar' element={<CalendarLazy />} /> */}
                            <Route path='/assets' element={<AssetsLazy />} />
                            {/* <Route path='/download' element={<DownloadLazy />} /> */}
                            {/* <Route path='/contract-specification' element={<ContractLazy />} /> */}
                            {/* <Route path='/conditions' element={<ConditionsLazy />} /> */}
                            {/* <Route path='/commodities' element={<CommoditiesLazy />} /> */}
                            {/* <Route path='/deposit-withdrawals' element={<DepositsLazy />} /> */}
                            {/* <Route path='/about-us' element={<RegisterLazy />} /> */}
                            {/* <Route path='/account' element={<AccountLazy />} /> */}
                            {/* <Route path='/legal-documents' element= {<LegalLazy />} /> */}
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </div>
            {/* <RiskBanner /> */}
            <Footer />
        </div>
    )
}

export default App;