import React from "react";
import "./accountPage.scss";
import { useSelector } from 'react-redux';


export default function AccountTypes() {
  const langTokens = useSelector((state) => state.language.data);

  let arrTypes = [
    { title: "Silver", leverage: "200:1", accountManager: langTokens.ACCOUNT_TYPES_YES, swap:langTokens.ACCOUNT_TYPES_NO, decimal: langTokens.ACCOUNT_TYPES_YES, VPS: langTokens.ACCOUNT_TYPES_NO, news: langTokens.ACCOUNT_TYPES_NO },
    { title: "Gold", leverage: "300:1", accountManager: langTokens.ACCOUNT_TYPES_YES, swap: "25%", decimal: langTokens.ACCOUNT_TYPES_YES, VPS: langTokens.ACCOUNT_TYPES_NO, news: langTokens.ACCOUNT_TYPES_YES },
    { title: "Platinum", leverage: "400:1", accountManager: langTokens.ACCOUNT_TYPES_YES, swap: "50%", decimal: langTokens.ACCOUNT_TYPES_YES, VPS: langTokens.ACCOUNT_TYPES_YES, news: langTokens.ACCOUNT_TYPES_YES },
    // {title: "", leverage: "", accountManager: "", swap: "", decimal: "", VPS: "", news: ""},

  ]

  return (
    <div className="accountPage container">
      <h1 className='indent__title_auxilary'>{langTokens.ACCOUNT_TYPES_TITLE}</h1>
      <p>{langTokens.ACCOUNT_TYPES_TEXT}</p>
      <div className="typesCardBox indent__title_auxilary">
        {arrTypes.map((i, index) =>
          <div className="typeCard" key={index + i.title}>
            <div className="typeCard__titleBox">
              <h2>
                {i.title}
              </h2>
            </div>
            <hr />
            <ul>
              <li><div className="checkmark checkmark--active" />{langTokens.ACCOUNT_TYPES_LEVERAGE}: {i.leverage}</li>
              <li><div className={`checkmark ${i.accountManager == "Yes" ? "checkmark--active" : "checkmark--inactive"}`} />{langTokens.ACCOUNT_TYPES_MANAGER}: {i.accountManager}</li>
              <li><div className={`checkmark ${i.swap == "No" ? "checkmark--inactive" : "checkmark--active"}`} />{langTokens.ACCOUNT_TYPES_SWAP}: {i.swap}</li>
              <li><div className="checkmark checkmark--active" />{langTokens.ACCOUNT_TYPES_DECIMAL}: {i.decimal}</li>
              <li><div className={`checkmark ${i.VPS == "Yes" ? "checkmark--active" : "checkmark--inactive"}`} />{langTokens.ACCOUNT_TYPES_VPS}: {i.VPS}</li>
              <li><div className={`checkmark ${i.news == "Yes" ? "checkmark--active" : "checkmark--inactive"}`} />{langTokens.ACCOUNT_TYPES_NEWS}: {i.news}</li>
            </ul>
          </div>
        )}

      </div>
    </div>
  )
}