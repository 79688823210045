import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeLanguage } from '../core/languageSlice';
import en from '../languages/en.json';
import pl from '../languages/pl.json';

const Language = (lang) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let selectedLanguageTokens;

        // Определение языка
        switch (lang) {
            case 'pl':
                selectedLanguageTokens = pl;
                break;
            case 'en':
            default:
                selectedLanguageTokens = en;
                break;
        }

        // Обновление языка в Redux
        dispatch(changeLanguage(selectedLanguageTokens));
    }, [dispatch, lang]);

    // Если нужно вернуть язык, можно добавить возврат
    // return selectedLanguageTokens;
}

export default Language;
