import React from "react";
import "./accountPage.scss";
import { useSelector } from 'react-redux';


export default function RealAccount() {
  const langTokens = useSelector((state) => state.language.data);

  return (
    <div className="accountPage container">
      <div className="indent__title_auxilary">
        <h1 className='indent__title_auxilary'>{langTokens.ACCOUNT_REAL_TITLE}</h1>
        <p className="indent__title_auxilary">{langTokens.ACCOUNT_REAL_TEXT}</p>
        <h3>{langTokens.ACCOUNT_REAL_TRADING}</h3>
        <p>{langTokens.ACCOUNT_REAL_TRADING_TEXT}</p>
        <h3>{langTokens.ACCOUNT_REAL_LIQUIDITY}</h3>
        <p>{langTokens.ACCOUNT_REAL_LIQUIDITY_TEXT}</p>
        <h3>{langTokens.ACCOUNT_REAL_DEPOSIT}</h3>
        <p>{langTokens.ACCOUNT_REAL_DEPOSIT_TEXT}</p>
      </div>
    </div>
  )
}