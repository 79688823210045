import React from "react";
import "./accountPage.scss";
import { useSelector } from 'react-redux';


export default function AccountWithdrawals() {
  const langTokens = useSelector((state) => state.language.data);

  return (
    <div className="accountPage container">
      <div>
        <h1 className='indent__title_auxilary'>{langTokens.ACCOUNT_WITHDRAWALS_TITLE}</h1>
        <p className="indent__title_auxilary">{langTokens.ACCOUNT_WITHDRAWALS_TEXT}</p>
        <h3>{langTokens.ACCOUNT_WITHDRAWALS_CRYPTO}</h3>
        <p>{langTokens.ACCOUNT_WITHDRAWALS_CRYPTO_TEXT}</p>
        <h3>{langTokens.ACCOUNT_WITHDRAWALS_SECURITY}</h3>
        <p>{langTokens.ACCOUNT_WITHDRAWALS_SECURITY_TEXT}</p>
        <h3>{langTokens.ACCOUNT_WITHDRAWALS_COMPLIANCE}</h3>
        <p>{langTokens.ACCOUNT_WITHDRAWALS_COMPLIANCE_TEXT}</p>
      </div>
    </div>

  )
}