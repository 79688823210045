import React from "react";
import "./accountPage.scss";
import { useSelector } from 'react-redux';


export default function AccountVerification() {
  const langTokens = useSelector((state) => state.language.data);

  return (
    <div className="accountPage container">
      <div className="indent__title_auxilary">
        <h1 className='indent__title_auxilary'>{langTokens.ACCOUNT_VERIFICATION_TITLE}</h1>
        <p className="indent__title_auxilary">{langTokens.ACCOUNT_VERIFICATION_TEXT}</p>
        <h3>{langTokens.ACCOUNT_VERIFICATION_KYC}</h3>
        <p>{langTokens.ACCOUNT_VERIFICATION_KYC_TEXT}</p>
        <h3>{langTokens.ACCOUNT_VERIFICATION_ENCRYPTED}</h3>
        <p>{langTokens.ACCOUNT_VERIFICATION_ENCRYPTED_TEXT}</p>
        <h3>{langTokens.ACCOUNT_VERIFICATION_SECURITY}</h3>
        <p>{langTokens.ACCOUNT_VERIFICATION_SECURITY_TEXT}</p>
      </div>
    </div>

  )
}